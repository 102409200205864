import Custom404Section from 'components/core/Custom404Section'
import Footer from 'components/layout/Footer'
import AnyHeroWrapper from 'components/renderers/AnyHeroWrapper'
import HeaderContainer from 'components/renderers/HeaderContainer'
import Env from 'Env'
import { TopPosterModel } from 'models/body/TopPosterModel'
import { MenuModel } from 'models/menu/MenuModel'
import { GetStaticProps, NextPage } from 'next'
import Head from 'next/head'
import React, { Fragment } from 'react'
import { ApiPromise } from 'services/ApiPromise'
import { Converters } from 'services/Converters'

type Props = {
  menu: MenuModel
  footer: MenuModel
  posters: {
    data: TopPosterModel[]
  }
}

const Error404: NextPage<Props> = (props) => {
  return (
    <Fragment>
      <Head>
        <title>404 - SecureMyEmail</title>
        <meta name="description" content="We're sorry, but we can't find the page you're looking for. Please visit SecureMyEmail home page. Hope you'll have better luck there." />
      </Head>
      <HeaderContainer posters={props.posters} menu={props.menu} />

      <AnyHeroWrapper
        componentClassNames={`inner-banner sm:pb-0  md:pb-0 md:text-2xl pt-[50px] pb-[20px] bg-inner-banner bg-center bg-no-repeat bg-cover text-center z-10 relative text-lg text-white font-lato`}
      ></AnyHeroWrapper>
      <Custom404Section />
      <Footer data={props.footer} />
    </Fragment>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const allPromise = ApiPromise.Layout.GetMainAndFooterMenu()
  const [menuPromise, footerPromise, postersPromise] = await Promise.all(allPromise)
  const [menu, footer, posters] = Converters.KeysConvert.ConvertAllToLower([menuPromise.data, footerPromise.data, postersPromise.data])

  return { props: { menu, footer, posters }, revalidate: Env.PropsRevalidateTime }
}

export default Error404
