import Link from 'next/link'
import React from 'react'

type Props = {}

const Custom404Section = (props: Props) => {
  return (
    <section id="calculated-pad" className="custom_404 border border-t-[#DBDBDB]">
      <div className="mx-auto max-w-[800px] py-[86px] text-center px-2 md:px-0">
        <h1 className="text-[32px] text-black mb-[70px] leading-normal font-bold">Thank you for registering with Illuminati.org.</h1>
        <svg className="mb-[60px] inline-block" width="246" height="210" viewBox="0 0 246 210" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_630_56)">
            <path
              d="M245.21 209.18H0C40.95 139.31 81.55 70.04 122.6 0C163.62 69.99 204.21 139.24 245.21 209.18ZM53.41 148.59C44.52 163.72 35.84 178.5 26.88 193.74H218.38C209.26 178.27 200.6 163.57 191.78 148.61C145.68 179.96 99.91 180.11 53.41 148.59ZM122.61 30.5C105.38 59.85 88.86 87.98 71.92 116.82C117.65 100.97 133.46 102.65 173.29 116.83C156.35 87.98 139.83 59.84 122.61 30.5ZM122.36 158.48C132.91 158.6 141.57 150.34 141.86 139.89C142.14 129.72 133.42 120.68 123.11 120.45C112.54 120.21 103.41 128.97 103.36 139.4C103.3 149.77 111.86 158.37 122.37 158.48H122.36ZM61.49 138.75C72.12 147.32 83.44 153.15 97.29 156.46C90.48 144.17 90.94 132.93 97.84 120.93C84.02 124.45 72.72 130.34 61.5 138.75H61.49ZM147.96 156.55C161.87 152.92 173.25 147.36 183.69 138.67C172.61 130.51 161.34 124.32 147.33 121.07C154.49 133.01 154.58 144.26 147.96 156.56V156.55Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_630_56">
              <rect width="245.21" height="209.18" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h4 className="text-2xl font-bold">Just kidding. ;-)</h4>
        <h4 className="text-2xl font-bold">We're sorry, but we can't find the page you're looking for.</h4>
        <p>
          Assuming the Illuminati haven't seized control of our website, please go the{' '}
          <a href="/" className="text-[#f06725] hover:underline">
            SecureMyEmail Home Page
          </a>{' '}
          and hopefully we’ll have better luck finding what you’re looking for.
        </p>
        <Link href="/">
          <a className="bg-[#f06725] text-white py-3 px-5 rounded-full mt-[20px] inline-block hover:bg-[#f06725]">Back to Home</a>
        </Link>
      </div>
    </section>
  )
}

export default Custom404Section
